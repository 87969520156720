
import { defineComponent, ref, onMounted, computed } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRoute } from "vue-router";
import moment from "moment";
import Datepicker from "vue3-datepicker";
import router from "@/router";
import store from "@/store";
import {
  addProductionCutting,
  getProductionCuttingById,
  productionCuttingObjClone,
} from "@/core/data/productionCutting";
import { getError, getSuccess } from "@/core/helpers/utils";
import { StatusType } from "@/core/data/production";
export default defineComponent({
  name: "production-cutting-form",
  components: {
    Datepicker,
  },
  setup() {
    const route = useRoute();
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const tab2 = ref<any>(null);
    const file = ref<any>(null);
    const multiForm = ref<any>({});
    const fileError = ref<any>("");
    const po_id = ref<any>("");
    const purchase_order = ref<any>({});
    const selectedTab = ref<any>("Details");
    const isEdit = ref<boolean>(true);
    const isAdd = ref<boolean>(true);
    const universityList = ref<Array<any>>([]);
    const formData = ref(productionCuttingObjClone());
    const rules = ref({
      qty: [
        {
          required: true,
          message: "Type is required",
          trigger: "change",
        },
      ],
      status: [
        {
          required: true,
          message: "Status is required",
          trigger: "change",
        },
      ],
    });
    onMounted(() => {
      multiForm.value = {
        multiple_mobile: [""],
        multiple_email: [""],
      };
      formData.value = productionCuttingObjClone();
      if (route.params.cuttingId) {
        isEdit.value = false;
        isAdd.value = false;
        getItemData();
      }
    });
    const setEdit = (status) => {
      isEdit.value = status;
    };
    const getItemData = () => {
      getProductionCuttingById(route.params.cuttingId).then(
        (ProductionItem: any) => {
          formData.value = {
            ...ProductionItem,
          };
          multiForm.value = {
            multiple_mobile: ProductionItem.multiple_mobile.split(","),
            multiple_email: ProductionItem.multiple_email.split(","),
          };
        }
      );
    };

    const setSelectedTab = (name) => {
      selectedTab.value = name;
      getItemData();
    };
    const checkMaxSize = () => {
      fileError.value = "";
      if (file.value?.files[0]?.size > 1024 * 500) {
        fileError.value = "Max Size Should be 500kb";
      }
    };
    const productionId = computed(() => {
      return route.params.id;
    });
    const productionCuttingId = computed(() => {
      return route.params.cuttingId;
    });
    const selectedMaterial = computed(() => {
      return route.params.designMaterialId;
    });
    const selectedDesign = computed(() => {
      return route.params.designId;
    });
    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          let reqObj: any = { ...formData.value };
          reqObj.production_id = productionId.value;
          reqObj.design_material_item_id = selectedMaterial.value;
          addProductionCutting(reqObj, route.params.cuttingId)
            .then((responseData) => {
              loading.value = false;

              Swal.fire({
                text: getSuccess(
                  responseData,
                  "Form has been successfully submitted!"
                ),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                setTimeout(() => {
                  if (!route.params.cuttingId) {
                    router.push({
                      name: "apps-cutting-production",
                      params: {
                        id: productionId.value,
                        designId: selectedDesign.value,
                        designMaterialId: selectedMaterial.value,
                      },
                    });
                  } else {
                    isEdit.value = false;
                    getItemData();
                  }
                }, 200);
              });
            })
            .catch((err) => {
              loading.value = false;
              Swal.fire({
                html: getError(err),
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            });
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };
    const handleInput = (e) => {
      console.log("input");
    };
    const universityListComputed = computed(() => {
      return universityList.value.map((item) => ({
        ...item,
      }));
    });
    const userData = computed(() => {
      return store.getters.currentUser;
    });
    const isAdmin = computed(() => {
      return ["ADMIN"].indexOf(userData.value.role) > -1;
    });
    const onClickBack = () => {
      router.back();
    };

    return {
      onClickBack,
      isEdit,
      setEdit,
      formData,
      rules,
      formRef,
      submit,
      loading,
      file,
      moment,
      isAdd,
      handleInput,
      universityListComputed,
      isAdmin,
      po_id,
      productionId,
      setSelectedTab,
      selectedTab,
      purchase_order,
      tab2,
      multiForm,
      checkMaxSize,
      fileError,
      StatusType,
    };
  },
});
